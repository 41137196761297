import React from 'react';
import NavbarComponent from './components/Navbar';
import HeroSection from './components/Header';
import FeaturesSection from './components/Features';
import About from './components/About';
import Footer from './components/Footer';
import ContactUsSection from './components/Contact';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from '@vercel/speed-insights/react';

function App() {
  return (
    <>
      <NavbarComponent />
      <HeroSection />
      <Analytics />
      <SpeedInsights />
      <FeaturesSection />
      <About />
      
      <ContactUsSection />
      <Footer />
    </>
  );
}

export default App;


