import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './AboutUsSection.css';

const AboutUsSection = () => {
  return (
      <Container id="about" className="my-5">
          <h2 className="about-title">About Us</h2>
          <hr className="about-divider" style={{ marginTop: '0.5rem' }} />
          <p className="about-description">
          Sky Strike is an innovative technology company dedicated to preventing and containing violent incidents. With a focus on proactive threat detection and prevention, we develop advanced systems designed to safeguard communities and empower emergency services in crisis situations. Our mission is to create a safer world by not just detecting, but actually containing incidents and preventing deaths and injuries.
          </p>
          <h3 className="why-choose-us-title">Why Choose Us?</h3>
          <Row className="mt-4">
              <Col md={6}>
                  <ul className="about-list">
                      <li>
                          <i className="fas fa-check-circle" style={{ color: '#0d6efd' }}></i>
                          Proactive Active Shooter Prevention &amp; Containment
                      </li>
                      <li>
                          <i className="fas fa-check-circle" style={{ color: '#0d6efd' }}></i>
                          Integrated Communication &amp; Emergency Response Systems
                      </li>
                  </ul>
              </Col>
              <Col md={6}>
                  <ul className="about-list">
                      <li>
                          <i className="fas fa-check-circle" style={{ color: '#0d6efd' }}></i>
                          Works With Security and Emergency Personnel
                      </li>
                      <li>
                          <i className="fas fa-check-circle" style={{ color: '#0d6efd' }}></i>
                          Highly Effective Ethical and Non-Lethal Approach
                      </li>
                  </ul>
              </Col>
          </Row>
      </Container>

  );
};

export default AboutUsSection;