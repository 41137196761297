import React, { useState, useEffect } from 'react';
import './VideoSlider.css';  // Ensure you link the CSS file

// const videos = [
//   { src: '/intro.mp4', description: { title: 'Sky-Strike', content: 'Sky Strike is an advanced AI-driven solution designed to enhance public safety by detecting firearms in real-time and deploying immobilizing lassos to prevent shootings. With integrated emergency alert systems and adaptive threat assessment, it ensures rapid response and effective crowd management during critical incidents.' }},
//   { src: '/detect.mp4', description: { title: 'Detection', content:['<strong>Real-time Weapon Detection:</strong> Uses AI-powered object detection to identify handguns and other firearms in public spaces instantly, ensuring rapid response.', '<strong>Advanced Threat Assessment:</strong> Identifies and categorizes potential threats based on weapon type and user behavior, giving a complete risk analysis.', '<strong>Crowd Protection Protocol:</strong> Issues audio-visual alerts to notify nearby people, directing them to safe exits, minimizing panic, and aiding crowd management.'] }},
//   { src: '/lasso.mp4', description: { title: 'Trigging Lasso', content:['<strong>Shooter Deterrent:</strong> Deploys multiple lassos or tethering devices to physically immobilize the shooter, preventing further actions.'] }}
// ];

const videos = [
  { src: '/intro.mp4', description: { title: 'Sky-Strike', content: 'Sky Strike is an advanced solution that will prevent Active Shooter incidents by containing the threat agent. It is an AI-driven solution designed to dramatically improve public safety. <i class="fas fa-shield-alt"></i> It includes cameras and emergency alert systems to ensure rapid response and more effective detection and response during critical incidents.' }},
  { src: '/detect.mp4', description: { title: 'Detection', content: ['<strong>Real-time Weapon Detection:</strong> <i class="fas fa-search"></i> Instantly identifies firearms in public spaces using AI-powered detection, ensuring quick responses.', '<strong>Advanced Threat Assessment:</strong> <i class="fas fa-brain"></i> Categorizes potential threats by analyzing weapon types and user behaviors for thorough risk analysis.', '<strong>Crowd Protection Protocol:</strong> <i class="fas fa-users"></i> Emits audio-visual alerts to guide people to safety, reducing panic and enhancing crowd control.'] }},
  { src: '/lasso.mp4', description: { title: 'Triggering Lasso', content: ['<strong>Shooter Deterrent:</strong> <i class="fas fa-network-wired"></i> Within a minute, remotely deploy multiple lassos or tethering devices to physically immobilize the shooter, thereby protecting innocent bystanders from further harm until law enforcement arrives.'] }}
];

const VideoSlider = () => {
  const [index, setIndex] = useState(0);
  const [isHovering, setIsHovering] = useState(false);
  const [slideIn, setSlideIn] = useState(true);

  useEffect(() => {
    let timer;
    if (!isHovering) {
      timer = setTimeout(() => {
        setSlideIn(false);
        setTimeout(() => {
          setIndex((prevIndex) => (prevIndex + 1) % videos.length);
          setSlideIn(true);
        }, 500); // Transition out time
      }, 5000); // Slide duration
    }
    return () => clearTimeout(timer);
  }, [index, isHovering]);

  return (
    <div className="video-slider-container"
         onMouseEnter={() => setIsHovering(true)}
         onMouseLeave={() => setIsHovering(false)}
         onClick={() => setIsHovering(!isHovering)}>
      <header className="slider-header">
        <h2>{videos[index].description.title}</h2>
        <div className="navigation">
  {videos.map((video, idx) => (
    <button 
      key={idx} 
      className={`nav-button ${index === idx ? 'active' : ''}`}
      onClick={() => setIndex(idx)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={{ all: 'unset', cursor: 'pointer', padding: '10px 20px', color: index === idx ? '#000' : '#666' }}>
      {video.description.title}
    </button>
  ))}
</div>

      </header>
      <div className={`video-content ${slideIn ? 'slide-in' : 'slide-out'}`}>
        <video key={videos[index].src} controls autoPlay loop muted>
          <source src={videos[index].src} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="text-content">
  {Array.isArray(videos[index].description.content) ? (
    <ul>
      {videos[index].description.content.map((item, idx) => (
        <li key={idx} dangerouslySetInnerHTML={{ __html: item }}></li>
      ))}
    </ul>
  ) : (
    <p dangerouslySetInnerHTML={{ __html: videos[index].description.content }}></p>
  )}
</div>

      </div>
    </div>
  );
};

export default VideoSlider;
